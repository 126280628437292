.recommendation-popover-container {
  width: 340px;
  height: 100%;
  min-height: 200px;
  max-height: 370px;
  border: 1px solid var(--global--secondary--BackgroundColor);
  background-color: var(--global--primary--BackgroundColor);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recommendation-items-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
}

.recommendation-item {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #202020;
  margin: 10px;
  height: 97px;
}

.recommendation-item-text {
  font-size: clamp(0.8rem, 2.5vw, 1rem);
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.recommendation-item-price-button {
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-items: center;
}

.recommendation-item-button {
  min-width: 50px;
  height: 30px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: var(--global--secondary--BackgroundColor);
  color: var(--global--secondary--Color);
  border-radius: 5px;
  text-align: center;
  padding: 0 10px;
}

.close-recommendation {
  background-color: red;
  color: var(--global--secondary--Color);
  border-radius: 5px;
  text-align: center;
  color: white;
  width: 35%;
  height: 30px;
  margin-top: 10px;
  align-self: flex-end;
}

/* .recommendation-popover-header {
  align-self: flex-start;
  margin-top: 20px;
  padding: 10px; 
} */

.recommendation-popover-content {
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
}

.recommendation-popover-footer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding: 10px;
  width: 80%;
}

.recommendation-loading {  
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.recommended-price-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.recommended-price-normal {
  text-decoration: line-through;
  font-size: 14px;
}

.recommended-price-percentage {
  font-size: 14px;
  color: red;
}

.recommended-price-recommended {
  text-align: center;
  color: red;
}