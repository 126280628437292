.addstock-component-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addstock-buy-container-stocks {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
  position: relative;
  margin-bottom: 100px;
}

/* h1 {
  margin: 0;
  margin-top: 25px;
  font-size: 30px;
  text-transform: uppercase;
} */

.addstock-form-field {
  width: 250px;
  text-align: center;
}

/* .back-link,
.back-link:link,
.back-link:visited {
  color: white;
  height: 20px;
} */

.addstock-yellow-button {
  background-color: var(--global--secondary--BackgroundColor);
  color: var(--global--secondary--Color);
  padding: 10px 0px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  width: 150px;
  border-radius: 5px;
  /* text-align: center; */
  margin: 2px;
}

.addstock-yellow-button-add-product {
  background-color: var(--global--secondary--BackgroundColor);
  color: var(--global--secondary--Color);
  border-radius: 5px;
  text-align: center;
  padding: 10px 0px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  width: 150px;
}

.addstock-yellow-button-add-product-ean {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--global--secondary--BackgroundColor);
  color: var(--global--secondary--Color);
  border-radius: 5px;
  text-align: center;
  margin: 0 15px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
  width: 50px;
  height: 25px;
}

.addstock-ean-input {
  margin-top: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  height: 25px;
  text-align: center;
}

.addstock-ean-quantity-input {
  width: 40px;
  height: 25px;
  border-radius: 5px;
  text-align: center;
  padding: 0;
}

.addstock-erase-ean {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 25px;
}

/* POPOVER */
/* .buy-popover-container {
  width: 300px;
  height: 200px;
  border: 1px solid var(--global--secondary--BackgroundColor);
  background-color: var(--global--primary--BackgroundColor);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
} */

/* .buy-popover-container-oc {
  width: 300px;
  height: 300px;
  border: 1px solid var(--global--secondary--BackgroundColor);
  background-color: var(--global--primary--BackgroundColor);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
} */

/* .buy-confirmation-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 250px;
} */

/* .buy-confirmation-buttons button {
  border-radius: 3px;
  color: white;
  width: 120px;
  height: 40px;
} */

/* .accept-buy-invoice {
  background-color: yellow;
  border-radius: 5px;
  text-align: center;
  width: 150px;
  height: 30px;
} */

/* .accept-buy-button {
  background-color: green;
} */

/* .cancel-buy-button {
  background-color: red;
} */

/* .notmember-buy-button {
  background-color: grey;
} */

.addstock-popover-info-message {
  width: 250px;
  text-align: center;
  margin: 28px 0;
}

/* .automatic-button {
  color: white;
  text-decoration: underline;
  padding: 15px 0px;
} */

/* .automatic-button:focus {
  outline: 0;
} */

.addstock-clear-ean-button {
  color: white;
  text-decoration: underline;
  width: auto;
  text-align: right;
  margin: 0;
}

.addstock-clear-ean-button:focus {
  outline: 0;
}

/* PURCHARSE ORDERS */
.addstock-order-list-title-table {
  display: flex;
  justify-content: center;
}

.addstock-order-list-table {
  height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addstock-order-list-title-table p,
.addstock-order-list-title-table h3 {
  margin: 0;
}

.addstock-order-list-title-table>div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
}

.addstock-order-list-title-table>div>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 10px;
  font-size: 12px;
  font-weight: 700;
  min-width: 0px;
}

.addstock-order-list-title-table .yellow-button {
  font-size: 12px;
  text-align: center;
  padding: 10px;
  width: unset;
}

/* .remove-product img {
  width: 15px;
  height: auto;
  color: var(--global--primary--Color);
} */

.addstock-order-list-title-table .cart-icon img {
  width: 25px;
  height: auto;
}

.addstock-order-list-title-table .cart-icon span {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  right: 11px;
  top: 6px;
  color: var(--global--primary--Color);
  background-color: var(--global--notification--Color);
  border-radius: 100%;
  width: 15px;
  height: 15px;
  font-size: 8px;
  text-align: center;
  z-index: 5;
}

.addstock-order-list-title-table .info {
  width: 150px;
}

.addstock-order-list-title-table .price {
  width: 100px;
}

.addstock-order-list-title-table .price-bulk {
  width: 100px;
  color: green;
}

.addstock-order-list-title-table .price-member {
  width: 100px;
  color: yellow;
}

.addstock-order-list-title-table .price-sellout {
  width: 100px;
  color: var(--global--secondary--BackgroundColor);
}

/* .fixed {
  background-color: var(--global--secondary--Color);
  width: 340px;
  position: fixed;
  top: 0;
} */

/* .scrollable {
  position: relative;
  background-color: unset;
} */

/* .fixed-cart-hr {
  background-color: var(--global--secondary--Color);
  position: fixed;
  width: 100%;
  top: 36px;
  margin-top: -3px;
} */

/* .scrollable-cart-hr {
  position: relative;
  background-color: unset;
  width: unset;
  margin-top: -3px;
} */

.addstock-amount-cell-cart {
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
  margin-bottom: 15px;
  font-size: 20px;
  padding: 0px 5px;
}

.addstock-order-list-title-table .subtotal {
  width: 100px;
  text-decoration: line-through;
}

.addstock-order-list-select {
  width: 300px;
  margin: 20px 0;
  padding: 15px 0;
}

/* EXTRAS */
/* .bulk-price-text {
  color: green;
  font-size: 10px;
} */

/* .bulk-price-text.smaller-bulk-text {
  font-size: 7px;
} */

/* .coupon-input {
  width: 100%;
  height: 20px;
  font-size: 14px;
  padding: 10px 0px;
  margin-top: 10px;
} */

/* .error-message p {
  color: red;
  font-size: 14px;
} */

/* .search-products-input {
  display: flex;
  flex-direction: row;
  width: 100%;
} */

/* .deliver-button {
  background-color: red;
  color: white;
  border-radius: 5px;
  text-align: center;
} */

.addstock-order-list-scrollable {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
  padding: 20px 0;
  width: 300px;
}

.addstock-input-invoice {
  padding: 10px;
  margin-right: 10px;
  width: 50%;
}

.addstock-row-elements {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.addstock-ean-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.addstock-order-number-cell {
  width: 100%;
  max-width: 200px;
  text-align: start;
}

.addstock-order-number-cell-number {
  width: 50px;
  text-align: start;
  margin: 0 15px;
}

.addstock-info {
  width: 100%;
  max-width: 150px;
  display: flex;
  overflow: scroll;
  overflow-y: auto;
  overflow-x: auto;
  height: 65px;
}

.addstock-info-number {
  width: 50px;
  text-align: center;
  margin: 0 15px;
  height: 65px;
}

.addstock-entry-confirmation-buttons {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.addstock-order-list-table-main {
  width: 400px;
}

.addstock-stock-to-enter-reset {
  padding: 2px;
  border-radius: 5px;
  color: #fff;
  background-color: brown;
  margin: 0;
  margin-top: 5px;
}

.addstock-entry-popover-container {
  width: 300px;
  height: 200px;
  border: 1px solid var(--global--secondary--BackgroundColor);
  background-color: var(--global--primary--BackgroundColor);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addstock-accept-entry-button {
  background-color: green;
  border-radius: 3px;
  color: #fff;
  width: 120px;
  height: 40px;
}

.addstock-cancel-entry-button {
  background-color: red;
  border-radius: 3px;
  color: #fff;
  width: 120px;
  height: 40px;
}
