:root {
  /* Background Colors */
  --global--primary--BackgroundColor: #000000;
  --global--secondary--BackgroundColor: #E6D51B;
  --global--disable--BackgroundColor: #dfded4a1;
  --global--notification--Color: #F55B5B;
  --global--sucess--Color: #008500;



  /* Font Colors */
  --global--primary--Color: #ffffff;
  --global--secondary--Color: #000000;
  --global--placeholder--Color: #7B7373;
  --global--error--Color: #F55B5B;
  --global--info--Color: #ffffff;
}

button {
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0;
  background-color: transparent;
}

.page-container {
  background-color: var(--global--primary--BackgroundColor);
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--global--primary--Color);
  overflow: hidden;
}

.error-message {
  color: var(--global--error--Color);
  height: 25px;
  max-width: 300px;
  text-align: center;
}

.info-message {
  color: var(--global--info--Color);
  height: 25px;
  max-width: 300px;
  text-align: center;
}

.success-message {
  color: green;
  height: 25px;
  max-width: 300px;
  text-align: center;
}

.page-sub-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  flex-grow: 1;
  margin-bottom: 100px;
  margin-top: 20px;
}

.yellow-button {
  background-color: var(--global--secondary--BackgroundColor);
  color: var(--global--secondary--Color);
  border-radius: 5px;
  text-align: center;
  margin: 2px;
}
.red-button {
  background-color: 'red';
  color: var(--global--secondary--Color);
  border-radius: 5px;
  text-align: center;
}

.inside-button-small {
  font-size: 10px;
  font-family: inherit;
}

.grey-button {
  background-color: var(--global--disable--BackgroundColor);
  color: var(--global--secondary--disabled);
  border-radius: 5px;
  text-align: center;
  margin: 2px;
}

.green-button {
  background-color: var(--global--sucess--Color);
  color: var(--global--primary--Color);
  border-radius: 5px;
  text-align: center;
}

.transparent-button {
  background-color: transparent;
  color: var(--global--primary--Color);
  border-radius: 5px;
  border: 1px solid var(--global--secondary--BackgroundColor);
  text-align: center;
}

input[type=text],
input[type=text]:focus {
  padding-left: 10px;
}
