.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15px;
  padding-top: 15px;
  padding-bottom: 50px;
  position: fixed;
  bottom: 0;
  background-color: var(--global--secondary--Color);
  border-top: 1px solid #ccc;
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 275px;
  height: 100%;
}

.footer-container img {
  max-width: 30px;
  max-height: 30px;
  padding: 0px 10px;
  height: auto;
  width: auto;
}

.footer-container .yellow-button,
.footer-container .transparent-button {
  width: 50px;
  padding: 5px 0px;
  font-size: 10px;
}

.invisible {
  visibility: hidden;
}