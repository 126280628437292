/* PRODUCT LIST */
.product-list-table {
  width: 360px;
  padding-bottom: 30px;
  padding-top: 20px;
}

.product-list-table p,
.product-list-table h3 {
  margin: 0;
}

.product-list-table .infinite-scroll > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
}

.product-list-table > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
}

.product-list-table .infinite-scroll > div > div,
.product-list-table > div > .cart-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 10px;
  font-size: 12px;
  font-weight: 700;
}

.product-list-table .yellow-button {
  font-size: 12px;
  text-align: center;
  padding: 10px;
  width: unset;
}

.product-list-table .cart-icon img {
  width: 25px;
  height: auto;
}

.product-list-table .cart-icon span {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  right: 11px;
  top: 6px;
  color: var(--global--primary--Color);
  background-color: var(--global--notification--Color);
  border-radius: 100%;
  width: 15px;
  height: 15px;
  font-size: 8px;
  text-align: center;
  z-index: 5;
}


.product-list-table .infinite-scroll .amount-cell {
  display: flex;
  flex-direction: row;
  padding-left: 0px;
  width: 50px;
  justify-content: flex-start;
  align-items: center;
}

.product-list-table .infinite-scroll .amount-cell > div {
  padding-right: 2px;
  padding-left: 2px;
  font-size: 18px;
}

.product-list-table .infinite-scroll .info {
  width: 200px;
}

.fixed {
  background-color: var(--global--secondary--Color);
  width: 340px;
  position: fixed;
  top: 0;
}

.scrollable {
  position: relative;
  background-color: unset;
}

.fixed-hr {
  background-color: var(--global--secondary--Color);
  position: fixed;
  width: 100%;
  top: 36px;
}

.scrollable-hr {
  position: relative;
  background-color: unset;
  width: unset;
}

.add-subtract {
  color: var(--global--primary--Color);
  font-size: 18px;
}

.yellow-title {
  color: var(--global--secondary--BackgroundColor);
}

.stock-text {
  color: white;
  font-size: 12px;
}

.product-name {
  font-size: 16px;
}

.no-price {
  border: 1px solid red;
  background-color: red;
  color: white;
  padding: 3px;
  border-radius: 5px;
}