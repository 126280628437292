/* POPULAR PRODUCTS */
.popular-products-slider {
  width: 365px;
  padding: 5px;
}

.popular-products-slider h2 {
  padding-left: 25px;
  font-size: 18px;
}

.slider-container {
  width: 295px;
  margin: 0 auto;
}

.slider-container p {
  margin: 0;
}

.bulk-price-text-reverse {
  color: white;
  font-size: 10px;
}

.slider-container .popular-product {
  height: 45px;
  width: 135px;
  padding: 0px 5px;
  /* border: 1px solid var(--global--secondary--BackgroundColor); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 10px;
  /* font-weight: 800; */
  /* color: var(--global--primary--Color); */
  text-align: left;
  margin: 0 auto;
}

.yellow-title {
  color: var(--global--secondary--BackgroundColor);
}

.popover .amount-cell {
  display: flex;
  flex-direction: row;
  color: var(--global--primary--Color);
  padding-bottom: 10px;
  font-size: 18px;
  justify-content: center;
}

.popover .amount-cell button {
  color: var(--global--primary--Color);
}

.popover {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
  background-color: var(--global--secondary--Color);
  border: 1px solid var(--global--primary--Color);
}

.amount-number {
  padding-left: 5px;
  padding-right: 5px;
  color: var(--global--primary--Color);
}

.popover .yellow-button {
  padding: 10px;
}
