  button {
    margin-right :5px;
 }

 .tabs-payment {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 5px;
  text-align: center;
  justify-content: center;
}

 .pay-button {
  background-color: #e6d51b;
  color: var(--global--secondary--Color);
  border-radius: 5px;
  text-align: center;
  font-size: 100%;
  width: 200px;
  font-size: 18px;
  height: 40px;
}

.transbank-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5px;
}

.transbank-input {
  width: 200px;
  margin-bottom: 10px;
  margin-right: 5px;
  height: 30px;
  border-radius: 5px;
  text-align: center;
  padding: 5px 0;
}

.resend-button {
  cursor: pointer;
  color: white;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  height: 40px;
  padding: 2px;
  width: 270px;
  border: 1px solid var(--global--secondary--BackgroundColor);
  margin: 5px;
}

.resend-button-disabled {
  color: gray;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  height: 40px;
  padding: 2px;
  width: 270px;
  border: 1px solid gray;
  margin: 5px;
}

.resend-buttons-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.rappi-form-field {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
}