.entry-container img {
  width: 200px;
}

.entry-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 400px;
  padding-bottom: 180px;
}

.entry-container h1 {
  margin: 0;
  margin-top: 50px;
  font-size: 30px;
  text-transform: uppercase;
}

.form-field {
  width: 250px;
  text-align: left;
}

.back-link,
.back-link:link,
.back-link:visited {
  color: white;
  height: 20px;
}

.entry-container input[type=text] {
  width: 100%;
  height: 20px;
  font-size: 14px;
  padding: 10px 10px;
  margin-top: 10px;
}

.entry-container .yellow-button {
  padding: 10px 0px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  width: 150px;
}

/* POPOVER */
.entry-popover-container {
  width: 300px;
  height: 200px;
  border: 1px solid var(--global--secondary--BackgroundColor);
  background-color: var(--global--primary--BackgroundColor);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.entry-confirmation-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 250px;
}

.entry-confirmation-buttons button {
  border-radius: 3px;
  color: white;
  width: 120px;
  height: 40px;
}

.accept-entry-button {
  background-color: green;
}

.cancel-entry-button {
  background-color: red;
}

.notmember-entry-button {
  background-color: grey;
}

.popover-info-message {
  width: 250px;
  text-align: center;
}