/* PRODUCT LIST */
.saved-orders-table {
  width: 360px;
  padding-bottom: 30px;
  padding-top: 20px;
}

.saved-orders-table p,
.saved-orders-table h3 {
  margin: 0;
}

.saved-orders-table > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
}

.saved-orders-table > div > div,
.saved-orders-table > div > .cart-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 10px;
  font-size: 12px;
  font-weight: 700;
}

.saved-orders-table .yellow-button {
  font-size: 12px;
  text-align: center;
  padding: 10px;
  width: unset;
}

.saved-orders-table .order-number-cell {
  display: flex;
  flex-direction: row;
  padding-left: 0px;
  width: 75px;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
}

.saved-orders-table .info {
  width: 200px;
}

.fixed {
  background-color: var(--global--secondary--Color);
  width: 350px;
  position: fixed;
  top: 0;
}

.scrollable {
  position: relative;
  background-color: unset;
}

.fixed-hr {
  background-color: var(--global--secondary--Color);
  position: fixed;
  width: 360px;
  top: 36px;
  margin: 0;
  margin-top: -1px;
}

.scrollable-hr {
  position: relative;
  background-color: unset;
  width: unset;
}

.yellow-title-saved-orders {
  color: var(--global--secondary--BackgroundColor);
  padding-bottom: 20px;
  padding-left: 10px;
}

.button-size-spacer {
  width: 67.52px;
}