.loading-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--global--secondary--Color);
}

.logo {
  width: 300px;
  height: 66px;
}