.errorboundary-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--global--primary--Color);
  background-color: var(--global--secondary--Color);
}

.errorboundary-logo {
  width: 300px;
  height: 66px;
}

.errorboundary-title {
  margin: 10px;
}

.errorboundary-error {
  
}

.errorboundary-button {
  font-size: 20px;
  margin: 20px;
  padding: 20px;
  background-color: var(--global--secondary--BackgroundColor);
  border-radius: 10px;
  font-weight: 600;
}