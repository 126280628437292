.content-cancel {
    margin: 5px;
  }

.red-button {
  background-color: crimson;
  color: var(--global--secondary--Color);
  border-radius: 5px;
  text-align: center;
  font-size: 80%;
  width: 100px;
  font-size: 14px;
  height: 40px;
  color: white;
}