.delivery-order-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 700px;
}

.delivery-order-form {
  display: flex;
  flex-direction: column;
}

.delivery-order-input {
  width: 145px;
  text-align: center;
  margin: 0 auto;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
}

.delivery-order-form-button {
  width: 170px;
  margin: 0 auto;
  margin-top: 40px;
  background-color: var(--global--secondary--BackgroundColor);
  color: var(--global--secondary--Color);
  text-align: center;
  padding: 10px;
  border-radius: 5px;
}

.delivery-order-detail-head {
  display: flex;
  flex-direction: column;
}

.delivery-order-table-wrapper {
  height: 220px;
  /* border-collapse: collapse; */
  overflow-y: auto;
}

.delivery-order-table-head {
  position: sticky;
  top: 0;
  background-color: #000000;
}

.delivery-order-table-row {
  padding: 5px;
  vertical-align: top;
  align-content: center;
}

.delivery-order-table-row-no-stock {
  padding: 5px;
  vertical-align: top;
  background-color: red;
  border-radius: 5px;
  align-content: center;
  font-weight: 700;
  font-size: larger;
}

.delivery-order-generate-buttons {
  margin-top: 40px;
  margin-bottom: 80px;
}