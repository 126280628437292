.smartpos-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.smartpos-container .yellow-button {
  padding: 20px 0px;
  width: 200px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
}

.row-button-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  flex-flow: wrap;
  margin: 8px 0px;
}
