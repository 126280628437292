/* GENERAL */
.page-sub-container {
  align-items: stretch;
  width: 100%;
}

.buy-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
  position: relative;
}

.buy-container > .yellow-button {
  padding: 20px 0px;
  width: 220px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 100px;
}

/* HEADER */
.header {
  width: 350px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.header p {
  margin: 0;
  text-align: center;
  font-size: 10px;
}

.header .logout {
  cursor: pointer;
  text-decoration: underline;
  color: var(--global--primary--Color);
  font-size: 10px;
}

/* SEARCH BAR */
.search-products {
  padding: 5px 10px;
  width: 325px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.clear-search-button {
  color: white;
  text-decoration: underline;
  text-align: left;
  width: 100%;
  cursor: pointer;
}

.search-products input {
  text-indent: 10px;
  width: 100%;
  height: 20px;
  font-size: 14px;
  padding: 10px 0px;
  padding-right: 10px;
  margin-right: 10px;
}

.search-products input:focus {
  background-image: none;
  text-indent: 0px
}

.search-products-input {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.search-options-wrapper {
  display: flex;
  justify-content: start;
  width: 100%;
}

.search-options {
  display: flex;
  width: 83%;
  align-items: start;
  margin-top: 0px;
  height: 100%;
}