.add-payment-button {
  width: 25px;
  height: 25px;
  background-color: green;
  color: white;
  border-radius: 5px;
  border: 1px solid var(--global--secondary--BackgroundColor);
  text-align: center; 
  padding-bottom: 3px;
}

.remove-payment-button {
  width: 25px;
  height: 25px;
  background-color:red;
  color: white;
  border-radius: 5px;
  border: 1px solid var(--global--secondary--BackgroundColor);
  text-align: center; 
  padding-bottom: 3px;
}

.money-input {
  width: 70px;
  height: 30px;
  font-size: 12px;
  margin: 0px;
  border: 0px;
  color: var(--global--secondary--Color);
  background-color: var(--global--primary--Color);
  text-align: center;
  font-weight: bold;
  border-radius: 5px;
}

.payment-row {
  padding-top: 3px;
  padding-bottom: 3px;
}