/* PRODUCT LIST */
.cart-list-table {
    width: 360px;
    padding-bottom: 30px;
    padding-top: 20px;
  }
  
.cart-list-table p,
.cart-list-table h3 {
  margin: 0;
}

.cart-list-table > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
}
  
.cart-list-table > div > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 10px;
  font-size: 12px;
  font-weight: 700;
  min-width: 0px;
}

.cart-list-table .yellow-button {
  font-size: 12px;
  text-align: center;
  padding: 10px;
  width: unset;
}

.remove-product img{
  width: 15px;
  height: auto;
  color: var(--global--primary--Color);
}

.cart-list-table .cart-icon img {
  width: 25px;
  height: auto;
}
  
.cart-list-table .cart-icon span {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  right: 11px;
  top: 6px;
  color: var(--global--primary--Color);
  background-color: var(--global--notification--Color);
  border-radius: 100%;
  width: 15px;
  height: 15px;
  font-size: 8px;
  text-align: center;
  z-index: 5;
}

/* .cart-list-table .amount-cell {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  padding-left: 0px;
  width: 50px;
  justify-content: flex-start;
}
  
.cart-list-table .amount-cell > div {
  padding-right: 5px;
} */

.cart-list-table .info {
  width: 150px;
}

.cart-list-table .price {
  width: 100px;
}

.cart-list-table .price-bulk {
  width: 100px;
  color: green;
}

.cart-list-table .price-member {
  width: 100px;
  color: yellow;
}

.cart-list-table .price-sellout {
  width: 100px;
  color: var(--global--secondary--BackgroundColor);
}

.cart-list-table .price-recommended {
  width: 100px;
  color: red;
}
  
.fixed {
  background-color: var(--global--secondary--Color);
  width: 340px;
  position: fixed;
  top: 0;
}

.scrollable {
  position: relative;
  background-color: unset;
}

.fixed-cart-hr {
  background-color: var(--global--secondary--Color);
  position: fixed;
  width: 100%;
  top: 36px;
  margin-top: -3px;
}

.scrollable-cart-hr {
  position: relative;
  background-color: unset;
  width: unset;
  margin-top: -3px;
}

.cart-list-table .amount-cell-cart {
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  width: 50px;
  color: white;
  align-items: center;
}

.cart-list-table .amount-cell-cart button {
  color: white;
  font-size: 20px;
  padding: 0px 5px;
}


.cart-list-table .subtotal {
  width: 100px;
  text-decoration: line-through;
}