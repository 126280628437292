.return-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 300px;
  padding-bottom: 200px;
}

.return-container h1 {
  margin: 0;
  font-size: 26px;
  text-align: center;
}

.return-container .yellow-button {
  padding: 20px 0px;
  width: 200px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
}

.return-container .longer-button {
  width: 300px;
}

.code-form-field {
  width: 250px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.code-form-field input[type=text] {
  width: 100%;
  height: 20px;
  font-size: 14px;
  padding: 10px 10px;
  margin-top: 10px;
  margin-bottom: 40px;
}

.error-message {
  color: var(--global--error--Color);
}

.return-cart-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
  min-height: 600px;
  position: relative;
}

.return-cart-container .yellow-button {
  padding: 20px 0px;
  width: 200px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
}

.return-cart-container .longer-button {
  width: 300px;
}

.return-cart-container .info,
.return-cart-container .amount-cell-cart,
.return-cart-container .price {
  border: 1px solid var(--global--primary--Color);
}

/* HEADER */
.header {
  width: 350px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.header p {
  margin: 0;
  text-align: center;
  font-size: 10px;
}

.header .logout {
  cursor: pointer;
  text-decoration: underline;
} 

/* POPOVER */
.entry-popover-container {
  width: 300px;
  height: 200px;
  border: 1px solid var(--global--secondary--BackgroundColor);
  background-color: var(--global--primary--BackgroundColor);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.entry-confirmation-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 250px;
}

.entry-confirmation-buttons button {
  border-radius: 3px;
  color: white;
  width: 120px;
  height: 40px;
}

.accept-entry-button {
  background-color: green;
}

.cancel-entry-button {
  background-color: red;
}

.notmember-entry-button {
  background-color: grey;
}

.popover-info-message {
  width: 250px;
}

.return-confirm {
  font-size: 35px;
}

.return-button-container {
  padding-bottom: 100px;
}

.stores-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.store-select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
}

.store-select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}