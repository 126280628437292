.login-form img {
  width: 200px;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: auto;
}

.login-form h1 {
  margin: 0;
  margin-top: 50px;
  font-size: 30px;
  text-transform: uppercase;
}

.form-field {
  width: 250px;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

.login-form input[type=text],
.login-form input[type=password] {
  width: 100%;
  height: 20px;
  font-size: 14px;
  padding: 10px 10px;
  margin-top: 10px;
}

.login-form .yellow-button {
  padding: 10px 10px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  width: 150px;
}

.version {
  color: #fff;
  text-align: center;
  font-size: 12px;
}