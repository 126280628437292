.billing-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 30px;
  margin-bottom: 150px;
}

.billing-data {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 300px;
}

.billing-data input {
  width: 280px;
  height: 50px;
  font-size: 20px;
  margin: 15px 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.billing-data input::placeholder {
  color: #7B7374;
  font-weight: 500;
}

.personal-data {
  text-align: center;
}

.billing-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.billing-container .yellow-button {
  height: 50px;
  width: 200px;
  font-size: 18px;
  margin-top: 20px;
}

.billing-rut {
  display: flex;
  flex-direction: row;
  gap: 10px;
}