.delivered-orders-table {
  width: 360px;
  padding-bottom: 30px;
  padding-top: 20px;
}

.delivered-orders-table p,
.delivered-orders-table h3 {
  margin: 0;
}

.delivered-orders-table > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
}

.delivered-orders-table > div > div,
.delivered-orders-table > div > .cart-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 10px;
  font-size: 12px;
  font-weight: 700;
}

.delivered-orders-table .yellow-button {
  font-size: 12px;
  text-align: center;
  padding: 10px;
  width: unset;
}

.delivered-orders-table .deliver-button {
  font-size: 12px;
  text-align: center;
  padding: 10px;
  width: unset;
}

.delivered-orders-table .green-button {
  font-size: 12px;
  text-align: center;
  padding: 10px;
  width: unset;
}

.delivered-orders-table .grey-button {
  font-size: 12px;
  text-align: center;
  padding: 10px;
  width: unset;
}

.order-details-container .yellow-button {
  padding: 10px 5px;
  margin: 0 auto;
  width: 120px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  color: black;
  border-radius: 5px;
}

.order-details-error-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.delivered-orders-table .order-number-cell {
  display: flex;
  flex-direction: row;
  padding-left: 0px;
  width: 100px;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
}

.delivered-orders-table .info {
  padding-left: 0px;
}

.delivered-orders-table .info-button {
  display: flex;
  flex-direction: row;
  padding-left: 0px;
  width: 50px;
  justify-content: flex-start;
  align-items: right;
}

.fixed {
  background-color: var(--global--secondary--Color);
  width: 350px;
  position: fixed;
  top: 0;
}

.scrollable {
  position: relative;
  background-color: unset;
}

.fixed-hr {
  background-color: var(--global--secondary--Color);
  position: fixed;
  width: 360px;
  top: 36px;
  margin: 0;
  margin-top: -1px;
}

.scrollable-hr {
  position: relative;
  background-color: unset;
  width: unset;
}

.yellow-title-delivered-orders {
  color: var(--global--secondary--BackgroundColor);
  padding-bottom: 20px;
  padding-left: 10px;
}

.button-size-spacer {
  width: 67.52px;
}

.cart-popover-container {
  width: 300px;
  height: 300px;
  border: 1px solid var(--global--secondary--BackgroundColor);
  background-color: var(--global--primary--BackgroundColor);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cart-type-container {
  height: auto;
}

.cart-popup-confirmation-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 250px;
}

.radio-input-container{
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 250px;
  margin-bottom: 5px;
}
.radio-input-container input {
  margin-right: 20px
}

.cart-popup-confirmation-buttons button {
  border-radius: 3px;
  color: white;
  width: 120px;
  height: 40px;
}

.accept-cart-button {
  background-color: green;
}

.cancel-button {
  background-color: red;
}

/* EXTRAS */
.bulk-price-text {
  color: green;
  font-size: 10px;
}

.bulk-price-text.smaller-bulk-text {
  font-size: 7px;
}

.coupon-input {
  width: 100%;
  height: 20px;
  font-size: 14px;
  padding: 10px 0px;
  margin-top: 10px;
}

.error-message p {
  color: red;
  font-size: 14px;
}

.search-products-input {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.deliver-button {
  background-color: red;
  color: white;
  border-radius: 5px;
  text-align: center;
}
