/* GENERAL */
.page-sub-container {
  align-items: stretch;
  width: 100%;
}
  
.cart-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
  min-height: 600px;
  position: relative;
}

.cart-confirmation-buttons .yellow-button {
  padding: 20px 0px;
  width: 220px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
}

.cart-confirmation-buttons .deactivated {
  background-color: var(--global--placeholder--Color);
}

.cart-confirmation-buttons {
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 130px;
}

.cart-confirmation-buttons .save-order-button {
  color: white;
  text-decoration: underline;
  margin-top: 10px;
}

/* HEADER */
.header {
  width: 350px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.header p {
  margin: 0;
  text-align: center;
  font-size: 10px;
}

.header .logout {
  cursor: pointer;
  text-decoration: underline;
}

/* TITLE */
.title {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  padding-left: 20px;
}

.title h3 {
  margin: 0;
  padding-left: 10px;
}

.title img {
  width: 25px;
  height: 25px;
}

.bottom-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 120px;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
}

.button-holder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 30px;
}

.total-points {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 160px;
  padding-top: 10px;
}

.total-points h1,
.total-points h5 {
  margin: 0;
}

.cart-discount-display h5 {
  margin: 0;
}

.cart-discount-display {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
}

.cart-discount-display h5 span {
  color: green;
  padding-left: 5px;
}

.subtotal {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 10px;
  width: 160px;
}

.subtotal h1,
.subtotal h5 {
  margin: 0;
}

.subtotal h1 {
  text-decoration: line-through;
}

.total {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 160px;
}

.total h1,
.total h5 {
  margin: 0;
}

.bottom-info .transparent-button {
  padding: 10px 0px;
  width: 150px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}

.transparent-button-recommendations {
  background-color: transparent;
  color: var(--global--primary--Color);
  border-radius: 5px;
  border: 1px solid var(--global--secondary--BackgroundColor);
  text-align: center;
  padding: 10px 0px;
  width: 190px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}

.button-holder button,
.button-holder input {
  margin: 0px 5px;
}

/* POPUP */
.cart-popover-container {
  width: 300px;
  height: 300px;
  border: 1px solid var(--global--secondary--BackgroundColor);
  background-color: var(--global--primary--BackgroundColor);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cart-type-container {
  height: auto;
}

.cart-popup-confirmation-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 250px;
  margin-top: 20px;
}

.radio-input-container{
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 250px;
  margin-bottom: 5px;
}
.radio-input-container input {
  margin-right: 20px
}

.cart-popup-confirmation-buttons button {
  border-radius: 3px;
  color: white;
  width: 120px;
  height: 40px;
}

.accept-cart-button {
  background-color: green;
}

.cancel-cart-button {
  background-color: red;
}

/* EXTRAS */
.bulk-price-text {
  color: green;
  font-size: 10px;
}

.bulk-price-text.smaller-bulk-text {
  font-size: 7px;
}

.coupon-input {
  width: 100%;
  height: 20px;
  font-size: 14px;
  padding: 10px 0px;
  margin-top: 10px;
}

.cart-error-message p {
  color: red;
  font-size: 14px;
}

.cart-popup-messages {
  height: 20px;
}

.cart-popup-success-messsage p {
  color: green;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
}

.cart-popup-error-messsage p {
  color: red;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
}

/* COUPONS */
.remove-coupon-button {
  width: 25px;
  height: 25px;
  background-color:red;
  color: white;
  border-radius: 5px;
  border: 1px solid var(--global--secondary--BackgroundColor);
  text-align: center; 
  padding-bottom: 3px;
}

.coupon-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 30px;
  align-items: center;
  gap: 5px;
}