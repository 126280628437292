.profile-container img {
  width: 200px;
}

.profile-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* height: 750px; */
  text-align: center;
}

.profile-container h1 {
  margin: 0px;
  margin-top: 5px;
  margin-bottom: 25px;
  font-size: 30px;
  text-transform: uppercase;
}

.profile-container h2 {
  margin: 0px;
  font-size: 24px;
  text-transform: uppercase;
}

.profile-container h3 {
  margin: 0px;
  font-size: 14px;
  margin-top: 15px;
  text-transform: uppercase;
}

.profile-container p {
  margin: 0px;
}

.profile-container .yellow-button {
  padding: 20px 0px;
  width: 200px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
}

.buy-type-buttons {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 50px;
}

.profile-acumulated-points {
  padding-top: 25px;
}

.cart-type-buttons {
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 35px;
  align-items: center;
}

.add-to-cart-button {
  background-color: var(--global--secondary--BackgroundColor);
  color: var(--global--secondary--Color);
  border-radius: 5px;
  text-align: center;
  padding: 10px;
}

.amount-cell {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.amount-cell > div {
  width: 30px;
}