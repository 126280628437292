.final-pay-container .yellow-button {
  width: 150px;
  font-size: 18px;
  height: 40px;
}

.final-pay-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 250px;
}

.payment-sequence-container {
  height: 100%;
  text-align: center;
}

.final-payment-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.final-payment-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 120px;
  margin: 0 auto;
  max-width: 150px;
}

.final-payment-buttons .yellow-button {
  width: 150px;
  height: 50px;
  font-size: 18px;
}

.rappi-payment-inputs input {
  height: 30px;
}

.rappi-payment-inputs {
  display: flex;
  flex-direction: column;
  min-height: 100px;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
}

.change-input {
  height: 30px;
}

.change-amount {
  color: green;
}

.yellow-button.deactivated,
.accept-entry-button.deactivated {
  background-color: var(--global--placeholder--Color);
}

.final-popover-container {
  width: 300px;
  height: 300px;
  border: 1px solid var(--global--secondary--BackgroundColor);
  background-color: var(--global--primary--BackgroundColor);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.cancel-payment-button button {
  color: white;
  text-decoration: underline;
  padding-left: 5px;
  cursor: pointer;
}

.auth-payment-button button {
  color: white;
  text-decoration: underline;
  padding-left: 5px;
  cursor: pointer;
}

.cancel-payment-button {
  text-align: center;
}

.auth-payment-button{
  text-align: center;
}

.waiting-payment-header {
  text-align: center;
}

.reset-qr {
  text-decoration: underline;
  color: white;
}

.auth-code-form{
  background-color: black;
  color:white;
}

.auth-code-form-content{
  display:flex;
  flex-direction: column;
  padding:30px !important;
}

.auth-code-input{
  font-size:14px;
  background: white;
  padding:10px
}

.auth-code-form-row-buttons{
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
}

.auth-code-button{
  padding:10px;
  font-size: 18px;
}

.auth-code-cancel-button{
  background-color: grey;
  border-radius: 5px;
}

.auth-code-error-message{
  font-size:14px;
}