/* PRODUCT LIST */
.return-list-table {
  width: 360px;
  padding-bottom: 30px;
  flex-grow: 1;
}

.return-list-table p,
.return-list-table h3 {
  margin: 0;
}

.return-list-table > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
}

.return-list-table > div > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 10px;
  font-size: 12px;
  font-weight: 700;
}

.return-list-table > div .no-border {
  border: none; 
  height: 20px;
  justify-content: flex-start;
}

.return-list-table .yellow-button {
  font-size: 12px;
  text-align: center;
  padding: 10px;
  width: unset;
}

.return-list-table .info {
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.return-list-table .price {
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.return-list-table .amount-cell-cart {
  width: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.return-list-table .amount-cell-cart > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.return-list-table .amount-input {
  width: 30px;
  height: 25px;
  font-size: 18px;
  margin: 0px;
  border: 0px;
  color: var(--global--secondary--Color);
  background-color: var(--global--primary--Color);
  text-align: center;
  font-weight: bold;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::placeholder {
  opacity: 1;
  color: var(--global--secondary--Color);
}

.fixed {
  background-color: var(--global--secondary--Color);
  width: 340px;
  position: fixed;
  top: 0;
}

.scrollable {
  position: relative;
  background-color: unset;
}

.fixed-cart-return-hr {
  background-color: var(--global--secondary--Color);
  position: fixed;
  width: 100%;
  top: 60px;
  margin-top: -3px;
}

.scrollable-cart-hr {
  display: none;
}

.amount-cell-cart {
  padding-left: 5px;
}